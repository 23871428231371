import React from "react";
import styled from '@emotion/styled';

import SEO from "../components/seo";
import PageTitle from '../components/PageTitle';
import EmailSubscribe from '../components/EmailSubscribe/EmailSubscribe';
import BlogIndex from '../components/BlogIndex/BlogIndex';

import useBlogData from '../hooks/use-blogdata';
import data from '../../content/data/site/EmailSubscribe.yml';

function BlogIndexPage() {
    return (
        <>
            <SEO title='Blog' />
            <CENTER>
                <PageTitle title='Blog' />
                <EmailSubscribe data={data} />
                <BlogIndex posts={useBlogData()} />
            </CENTER>
        </>
    );
}

const CENTER = styled('main')`
    width: 100vw;
    margin: 0 auto;
    background: #FFF;

    // image switches to fullscreen
    @media (max-width: 500px) {
        width: 100%;
    }
`;

export default BlogIndexPage;