import { graphql, useStaticQuery } from 'gatsby';

function useBlogData() {
    const data = useStaticQuery(graphql`
        query {
            allMdx(sort: {order: DESC, fields: [frontmatter___date] }) {
                nodes {
                    frontmatter {
                        title
                        date(formatString: "MMMM DD, YYYY")
                        author
                        excerpt
                        hero {
                            sharp: childImageSharp {
                                fluid(maxWidth: 800, maxHeight: 350) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    fields {
                        titleSlug
                    }
                }
            }
        }`);

    return (
        data.allMdx.nodes.map(
            function returnPostData(post) {
                return ({
                    title: post.frontmatter.title,
                    date: post.frontmatter.date,
                    author: post.frontmatter.author,
                    hero: post.frontmatter.hero.sharp.fluid,
                    excerpt: post.frontmatter.excerpt,
                    slug: `/${post.fields.titleSlug}/`,
                });
            }
        )
    );
}

export default useBlogData;