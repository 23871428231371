import React from 'react';
import styled from '@emotion/styled';

function EmailSubscribe({ data, showHeader=false }) {
    return (
        <BOX>
            {showHeader &&
            <HEADER>{data.header}</HEADER>}
            <COPY>{data.topline}</COPY>
            <FORM action="https://tonybronzo.us3.list-manage.com/subscribe/post" method="POST">
                <input type="hidden" name="u" value="f53557743d269e693574754bf" />
                <input type="hidden" name="id" value="cae642d5f5" />

                <INPUT type="email" name="MERGE0" placeholder="my@email.com"></INPUT>
                <BUTTON type="submit">{data.button}</BUTTON>
            </FORM>
        </BOX>
    );
}

const BOX = styled('div')`
    margin-bottom: 15px;
`;

const HEADER = styled('div')`
    font-size: 50px;
    font-weight: 600;
    line-height: 1;
    max-width: 640px;
    margin: 0 auto 20px auto;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
`;

const COPY = styled('p')`
    max-width: 640px;
    font-size: 28px;
    font-weight: 600;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    line-height: 1.4;
    margin: 0 auto 20px auto;

    @media (max-width: 700px) {
        font-size: 20px;
        padding: 0 30px;
        text-align: center;
    }
`;

const FORM = styled('form')`
    display: flex;
    justify-content: center;
    max-width: 700px;
    margin: 0 auto;

    @media (max-width: 550px) {
        flex-direction: column;
        align-items: center;
    }
`;

const INPUT = styled('input')`
    height: 52px;
    margin-right: 8px;
    background: #f3f3f3;
    border-radius: 16px;
    border: medium none;
    font-size: 18px;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    outline: currentcolor none 0px;
    padding: 0px 20px;
    transition: box-shadow 0.2s ease 0s, color 0.2s ease 0s;
    ${'' /* width: 100%; */}
    width: 300px;

    &:focus {
        box-shadow: inset 0 0 0 1px #09f;
        outline-width: 0px;
}
    }

    &::placeholder {
        color: #bababa;
    }
`;

const BUTTON = styled('button')`
    background: #09f;
    color: #fff;
    position: relative;
    appearance: none;
    text-decoration: none;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: -0.015em;
    display: inline-flex;
    flex-direction: row;
    place-items: center;
    place-content: center;
    height: 52px;
    padding: 0px 20px;
    border-radius: 16px;
    ${'' /* box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1),0px 2px 10px 0px rgba(0,0,0,0.08)); */}
    border: medium none;
    cursor: pointer;
    outline: currentcolor none medium;
    transition-duration: 0.2s;
    transition-property: background, color, opacity, box-shadow;

    &:hover {
        background: #07f;
    }

    @media (max-width: 550px) {
        margin-top: 10px;
    }
`;

export default EmailSubscribe;