import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

const tag_colors = ['#85f','#f36','#2dd','#09f'];

function BlogCard({ tag, title, date, hero, slug, index }) {
    return (
        <CARD to={slug}>
            {hero && typeof hero != 'string' ? <IMG fluid={hero} alt="" /> : <TempImage src={`${hero}/${index === 0 ? '800x280' : '380x280'}`} />}
            <CONTENT>
                <TAG color={tag_colors[Math.floor(Math.random() * tag_colors.length)]}>{tag}</TAG>
                <TITLE>{title}</TITLE>
                <DATE>{date}</DATE>
            </CONTENT>
        </CARD>
    );
}

const CARD = styled(Link)`
    width: 100%;
    height: 480px;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow:  rgba(0, 0, 0, 0.08) 0px 6px 8px 0px, rgba(0, 0, 0, 0.04) 0px 0px 0px 1px;
    background-color: rgb(255, 255, 255);
    overflow-x: hidden;
    overflow-y: hidden;
    transition-duration: 0.2s, 0.2s;
    transition-timing-function: initial, initial;
    transition-delay: initial, initial;
    transition-property: box-shadow, transform;
    transform: translate3d(0px, 0px, 0px);
    cursor: pointer;
    text-decoration: none;

    &:hover {
        transform: translateY(-4px);
        box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 12px 0px, rgba(0, 0, 0, 0.04) 0px 0px 0px 1px;
    }

    @media (max-width: 500px) {
        height: 530px;
    }
`;

const IMG = styled(Img)`
    height: 280px;
    width: 100%;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    flex-shrink: 0;
`;

// temp
const TempImage = styled('img')`
    height: 280px;
    width: 100%;
    background-size: cover;
    object-fit: cover;
    background-position-x: center;
    background-position-y: center;
    flex-shrink: 0;
`;

const CONTENT = styled('div')`
    padding: 36px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
`;

const TAG = styled('p')`
    color: ${props => props.color};  // temp
    font-size: 16px;
    line-height: 1;
    margin-top: 0px;
    margin-bottom: 10px;
`;

const TITLE = styled('h3')`
    color: #111;
    font-size: 24px;
    ${'' /* font-weight: 600; */}
    letter-spacing: -0.5px;
    line-height: 1.2;
    margin: 0px;
    height: 100%;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;
`;

const DATE = styled('p')`
    color: #999;
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
    margin-top: 6px;
    text-rendering: optimizeLegibility; // ??
`;

export default BlogCard;