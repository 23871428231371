import React from 'react';
import styled from '@emotion/styled';

import BlogCard from '../BlogCard/BlogCard';

const temp_tags = ['insight', 'clarity', 'breakthrough', 'transform'];

function BlogIndex({ posts }){
    return (
        <CONTAINER>
            {posts.map(function makePostCard(post, index) {
                return (
                    <BlogCard 
                        tag={temp_tags[Math.floor(Math.random() * temp_tags.length)]}
                        title={post.title}
                        // date={post.date}
                        hero={post.hero || post.imageUrl}
                        slug={post.slug || post.id}
                        index={index}
                    />
                );
            })}
        </CONTAINER>
    );
}

const CONTAINER = styled('div')`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    justify-items: center;
    max-width: 1200px;
    padding: 80px 0;
    margin: 0 auto;

    a:first-child {
        grid-column: auto / span 2;

        @media (max-width: 700px) {
            grid-column: auto;
        }
    }

    @media (max-width: 1200px) {
        margin: 0 25px;
        grid-template-columns: 1fr 1fr;

    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }

    @media (max-width: 500px) {
        margin: 0 10px;
    }
`;

export default BlogIndex;